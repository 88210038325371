import { useState } from 'react';
import { useHistory, Prompt } from 'react-router';

import { ConfirmationModal } from 'pjm-dls';
import IntlMessages from 'util/IntlMessages';

const DiscardUnsavechangesPopup = ({
  modifyValue,
  confirmedNavigation,
  setConfirmedNavigation,
  modifyCancelButton,
  checkObjModifiedCallback,
  setTabChange,
  tabChange,
  updatedTabValues,
  handleCancel,
}) => {
  const [navigationNextLocation, setNavigationNextLocation] = useState('');
  const history = useHistory();
  const navigate = (path) => history.push(path);
  const params = new URLSearchParams(window.location.search);
  const sectionQuery = params.get('section');
  const sourceSection = params.get('sourceSection');
  const tabQuery = params.get('tab');
  const mode = params.get('mode');

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && modifyValue) {
      setConfirmedNavigation(true);
      setNavigationNextLocation(nextLocation);
      return false;
    }
    if (!modifyValue && confirmedNavigation) {
      setConfirmedNavigation(false);
    }
    return true;
  };
  const onNavigationCloseConf = () => {
    setConfirmedNavigation(false);
  };
  const navigationConfirmModal = () => {
    if (tabChange) {
      let path = `${window.location.pathname}?tab=${tabQuery}&section=${updatedTabValues.newValue}&sourceSection=${sourceSection}`;
      if (mode) {
        path = `${path}&mode=${mode}`;
      }
      setTabChange(false);
      navigate(path);
    } else if (navigationNextLocation) {
      navigate(
        `${navigationNextLocation?.pathname}${navigationNextLocation?.search}`,
      );
    } else {
      handleCancel();
    }
  };

  return (
    <>
      <Prompt when={modifyValue} message={handleBlockedNavigation} />
      <ConfirmationModal
        message={<IntlMessages id="cbre-pjm-pds-form-root.leavePage" />}
        open={confirmedNavigation}
        fcName="fc-black"
        onCloseModal={navigationConfirmModal}
        onConfirmAction={onNavigationCloseConf}
      />
    </>
  );
};

export default DiscardUnsavechangesPopup;
